import React, { useState } from 'react'

import MenuButton from './menu-button'
import Menu from './menu'
import Modal from '../ui/modal'

import { Wrap, Logo, Arrow } from './index.styled'

const Header = ({ layout, ...props }) => {
  const [openMenu, setOpenMenu] = useState(false)
  return (
    <Wrap className={[layout, openMenu && 'open'].join(' ')} {...props}>
      {layout === 'back' || (layout === 'back-black' && !openMenu) ? (
        <Arrow to={'/portfolio'} onClick={() => setOpenMenu(false)} />
      ) : (
        <Logo id={'logo'} to={'/'} onClick={() => setOpenMenu(false)} />
      )}
      <MenuButton
        onClick={() => setOpenMenu(!openMenu)}
        open={openMenu && 'open'}
        layout={layout}
      />
      <Modal open={openMenu} timeout={0}>
        <Menu onClose={() => setOpenMenu(false)} layout={layout} />
      </Modal>
    </Wrap>
  )
}
export default Header
