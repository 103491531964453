import React, { useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Wrapper,
  Item,
  Li,
  Primary,
  Social,
  SocialLi,
  SocialLink,
} from './index.styled'

const Menu = ({ layout = '', onClose = () => {} }) => {
  const {
    menuContent: { content: links },
  } = useStaticQuery(graphql`
    query MenuQuery {
      menuContent {
        content {
          pimary {
            text
            url
          }
          social {
            name
            url
          }
        }
      }
    }
  `)

  const menuRef = useRef(null)

  return (
    <Wrapper id={'menu'} ref={menuRef} className={layout}>
      <Primary>
        {links.pimary.map((item, index) => (
          <Li key={`links-item-${index}`} onClick={onClose} index={index}>
            <Item to={item.url}>{item.text}</Item>
          </Li>
        ))}
      </Primary>
      <Social>
        {links.social.map((item, index) => (
          <SocialLi key={`linsocialks-item-${index}`} onClick={onClose}>
            <SocialLink
              href={item.url}
              className={item.name}
              target={'_blank'}
            />
          </SocialLi>
        ))}
      </Social>
    </Wrapper>
  )
}

export default Menu
