import { createGlobalStyle } from 'styled-components'
import { mediaMax } from 'helpers/media-queries'

const GlobalStyle = createGlobalStyle`
  :root {
  } 
  li {
    list-style: none;
  }
  * {
    margin: 0;
    padding: 0;
    font-family: inherit;
    box-sizing: border-box;
  }
  body {
    font-family: "Graphik", Helvetica, Arial, Tahoma, Geneva, Kalimati, sans-serif;
    font-weight: 300;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: rgb(51, 51, 51);
    line-height: 1.25;
    background: rgb(255, 255, 255);
    min-width: 320px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: scroll;
  }
  h1, h2, h3, h4, h5, h6, p, span, li, div, a {
    font-family: "Graphik";
    font-weight: 100;
    ${mediaMax('mobile')} {
      font-weight: 200;
    }
    ${mediaMax('desktopLarge')} {
      font-weight: 200;
    }
  }
  body.hidden {
    height: 100vh !important;
  }
  body.hidden .home-wrapper {
    height: 100vh;
    overflow: hidden;
  }

  .home-content.back-black{
    background: black;
  }


  .portal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 106;
    display: flex;
    justify-content: center;
    &.noHeader{
      z-index: 200;
    }
  }

`

export default GlobalStyle
