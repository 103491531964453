import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { mediaMax } from 'helpers/media-queries'
import fbIcon from '../../../icons/fb.svg'
import instaIcon from '../../../icons/instagram.svg'
import twitterIcon from '../../../icons/twitter.svg'

export const Item = styled((props) => <Link {...props} />)`
  color: rgb(255, 255, 255);
  transition: all 0.2s linear 0s;
  text-decoration: none !important;
`

export const Primary = styled.ul`
  margin: 0px;
  padding: 0px;
  list-style: none;

  ${mediaMax('desktopLarge')} {
  }
`
export const Social = styled.ul`
  position: absolute;
  bottom: 1.04167vw;
  margin: 0px;
  padding: 0px;
  list-style: none;
  font-size: 0px;

  ${mediaMax('desktopLarge')} {
    bottom: 20px;
  }
`
export const SocialLi = styled.li`
  display: inline-block;
  margin: 0px 0.78125vw;
  ${mediaMax('desktopLarge')} {
    margin: 0px 15px;
  }
`
export const SocialLink = styled.a`
  display: block;
  width: 1.04167vw;
  height: 1.04167vw;
  background: rgb(170, 170, 170);
  transition: all 0.4s linear 0s;
  mask-size: auto 100% !important;
  &.fb {
    -webkit-mask: url(${fbIcon}) no-repeat;
  }
  &.twitter {
    -webkit-mask: url(${twitterIcon}) no-repeat;
  }
  &.instagram {
    -webkit-mask: url(${instaIcon}) no-repeat;
  }
  ${mediaMax('desktopLarge')} {
    width: 20px;
    height: 20px;
  }
  ${mediaMax('mobile')} {
    width: 16px;
    height: 16px;
  }
`
export const Li = styled.li`
  padding: 0.520833vw 0px;
  text-align: center;
  font-size: 2.5vw;
  line-height: 1;
  font-weight: 100;
  letter-spacing: 0.104167vw;
  text-transform: uppercase;

  transform: translateY(20px);
  opacity: 0;
  transition: ${({ index }) => `0.25s ease calc(${index + 1} * 0.1s)`};

  .fade-enter-done & {
    opacity: 1;
    transform: translateX(0);
  }

  ${mediaMax('desktopLarge')} {
    padding: 10px 0px;
    font-size: 48px;
    letter-spacing: 2px;
  }
  ${mediaMax('mobile')} {
    font-size: 30px;
    line-height: 30px;
  }
`
export const Wrapper = styled.div`
  position: fixed;
  inset: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: rgb(39, 39, 39);
  z-index: 10;
  &.back {
    background: rgb(255, 255, 255);
    ${Item} {
      color: rgb(0, 0, 0);
    }
  }
  ${mediaMax('mobile')} {
  }
`
