import styled from 'styled-components'
import { mediaMax } from 'helpers/media-queries'

export const NavIcon = styled.div`
  height: 0.104167vw;
  background: rgb(226, 226, 226);
  position: relative;

  &::before,
  &::after {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    content: '';

    height: 0.104167vw;
    background: rgb(226, 226, 226);
    transition: all 0.25s ease 0s;
  }
  &::before {
    transform: translateY(-0.3125vw);
  }
  &::after {
    transform: translateY(0.3125vw);
  }
  &.open {
    visibility: hidden;
    &::before {
      visibility: visible;
      transform: rotate(45deg) !important;
    }
    &::after {
      visibility: visible;
      transform: rotate(-45deg) !important;
    }
  }

  ${mediaMax('desktopLarge')} {
    &::before,
    &::after {
      height: 2px;
    }
    height: 2px;
    &:before {
      transform: translateY(-6px);
    }
    &:after {
      transform: translateY(6px);
    }
  }
`
export const MenuButtonIcon = styled.button`
  width: 1.25vw;
  height: 1.25vw;
  cursor: pointer;
  background: none;
  border: none;
  &.back {
    ${NavIcon} {
      background: rgb(0, 0, 0);
      &::before,
      &::after {
        background: rgb(0, 0, 0);
      }
    }
  }
  ${mediaMax('mobile')} {
  }
  ${mediaMax('desktopLarge')} {
    right: 40px;
    width: 24px;
    height: 24px;
  }
`
