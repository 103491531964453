import React from 'react'
import { MenuButtonIcon, NavIcon } from './index.styled'

const MenuButton = ({ layout = '', open = '', onClick }) => {
  return (
    <MenuButtonIcon onClick={onClick} className={layout} id={'menu-button'}>
      <NavIcon className={open} />
    </MenuButtonIcon>
  )
}

export default MenuButton
