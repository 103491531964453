import React from 'react'
import styled from 'styled-components'
import { mediaMax } from 'helpers/media-queries'
import { Link } from 'gatsby'
import HeaderLogo from 'icons/logo.svg'
import ArrowIcon from 'icons/back.svg'

export const Logo = styled((props) => <Link {...props} />)`
  width: 7.55208vw;
  height: 1.82292vw;
  -webkit-mask: url(${HeaderLogo}) no-repeat;
  -webkit-mask-size: contain;
  background: rgb(226, 226, 226);
  display: flex;

  ${mediaMax('desktopLarge')} {
    width: 145px;
    height: 35px;
  }
  ${mediaMax('mobile')} {
    width: 150px;
    height: 39px;
  }
`
export const Arrow = styled((props) => <Link {...props} />)`
  width: 28px;
  height: 28px;
  -webkit-mask: url(${ArrowIcon}) no-repeat;
  -webkit-mask-size: contain;
  background: black;
  display: flex;
  .back-black & {
    background: rgb(226, 226, 226);
  }
`

export const Wrap = styled.header`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  padding: 1.25vw 2.08333vw;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;

  z-index: 107;

  &.back {
    background: rgba(255, 255, 255, 0.4);
    &.open {
      ${Logo} {
        background: black;
      }
    }
  }

  ${mediaMax('tablet')} {
    padding: 24px 20px;
  }
  ${mediaMax('desktopLarge')} {
    padding: 24px 40px;
  }
  ${mediaMax('mobile')} {
    padding: 21px 20px;
  }
`
