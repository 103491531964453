import React from 'react'
import PropTypes from 'prop-types'
import { Transition, TransitionGroup } from 'react-transition-group'
import { gsap, ScrollSmoother } from 'helpers/gsap'
import Header from 'components/header'

import 'helpers/fonts.css'
import GlobalStyle from 'helpers/global-styles'
import { LayoutWrapper, PageWrapper, ContentWrapper } from './index.styled'

const LayoutContent = ({ children, location }) => {
  return (
    <TransitionGroup component={null}>
      <Transition key={location.pathname} timeout={{ enter: 1000, exit: 1000 }}>
        {(status) => (
          <div className={`page ${status}`}>
            <LayoutWrapper>{children}</LayoutWrapper>
          </div>
        )}
      </Transition>
    </TransitionGroup>
  )
}

const Layout = (props) => {
  const {
    children,
    location,
    pageContext: { layout },
  } = props
  const refWrapper = React.useRef(null)

  let scrollerSmoother

  React.useLayoutEffect(() => {
    let gsapContext = gsap.context(() => {
      scrollerSmoother = ScrollSmoother.create({
        content: '.home-content',
        wrapper: '.home-wrapper',
        smooth: 2,
        effects: true,
      })
    }, refWrapper)

    return () => gsapContext.revert()
  }, [children])

  return (
    <>
      <div className="home-wrapper" ref={refWrapper}>
        <Header layout={layout} />
        <div className={['home-content', layout].join(' ')}>
          <PageWrapper>
            <GlobalStyle />
            <ContentWrapper>
              <LayoutContent location={location}>{children}</LayoutContent>
            </ContentWrapper>
          </PageWrapper>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
