import React from 'react'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { ModalContentWrap } from './index.styled'

const ModalContainer = ({ children, el = 'div', noHeader = false }) => {
  const [container] = useState(() => {
    return document.createElement(el)
  })

  useEffect(() => {
    container.classList.add('portal')
    if (noHeader) container.classList.add('noHeader')

    const parentElement = document.body

    if (!parentElement) {
      return
    }

    parentElement.appendChild(container)
    parentElement.style.overflow = 'hidden'

    return () => {
      parentElement.style.overflow = 'initial'
      parentElement.removeChild(container)
    }
  }, [container])

  return ReactDOM.createPortal(children, container)
}

const Modal = ({ open, children, timeout = 500, ...props }) => (
  <CSSTransition in={open} timeout={timeout} classNames={'fade'} unmountOnExit>
    <ModalContainer {...props}>
      <ModalContentWrap>{children}</ModalContentWrap>
    </ModalContainer>
  </CSSTransition>
)

export default Modal
